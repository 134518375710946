/* LAPTOP - DESKTOP WAS FIRST!! *******/
@font-face {
    font-family: 'Lab Grotesque';
    src: url('./Styles/Fonts/LabGrotesque-Italic.eot');
    src: url('./Styles/Fonts/LabGrotesque-Italic.eot?#iefix') format('embedded-opentype'),
        url('./Styles/Fonts/LabGrotesque-Italic.woff2') format('woff2'),
        url('./Styles/Fonts/LabGrotesque-Italic.woff') format('woff'),
        url('./Styles/Fonts/LabGrotesque-Italic.ttf') format('truetype'),
        url('./Styles/Fonts/LabGrotesque-Italic.svg#LabGrotesque-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: url('./Styles/Fonts/LabGrotesque-LightItalic.eot');
    src: url('./Styles/Fonts/LabGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Styles/Fonts/LabGrotesque-LightItalic.woff2') format('woff2'),
        url('./Styles/Fonts/LabGrotesque-LightItalic.woff') format('woff'),
        url('./Styles/Fonts/LabGrotesque-LightItalic.ttf') format('truetype'),
        url('./Styles/Fonts/LabGrotesque-LightItalic.svg#LabGrotesque-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: url('./Styles/Fonts/LabGrotesque-BoldItalic.eot');
    src: url('./Styles/Fonts/LabGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Styles/Fonts/LabGrotesque-BoldItalic.woff2') format('woff2'),
        url('./Styles/Fonts/LabGrotesque-BoldItalic.woff') format('woff'),
        url('./Styles/Fonts/LabGrotesque-BoldItalic.ttf') format('truetype'),
        url('./Styles/Fonts/LabGrotesque-BoldItalic.svg#LabGrotesque-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: url('./Styles/Fonts/LabGrotesque-Bold.eot');
    src: url('./Styles/Fonts/LabGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Styles/Fonts/LabGrotesque-Bold.woff2') format('woff2'),
        url('./Styles/Fonts/LabGrotesque-Bold.woff') format('woff'),
        url('./Styles/Fonts/LabGrotesque-Bold.ttf') format('truetype'),
        url('./Styles/Fonts/LabGrotesque-Bold.svg#LabGrotesque-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: url('./Styles/Fonts/LabGrotesque-Regular.eot');
    src: url('./Styles/Fonts/LabGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Styles/Fonts/LabGrotesque-Regular.woff2') format('woff2'),
        url('./Styles/Fonts/LabGrotesque-Regular.woff') format('woff'),
        url('./Styles/Fonts/LabGrotesque-Regular.ttf') format('truetype'),
        url('./Styles/Fonts/LabGrotesque-Regular.svg#LabGrotesque-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: url('./Styles/Fonts/LabGrotesque-Light.eot');
    src: url('./Styles/Fonts/LabGrotesque-Light.eot?#iefix') format('embedded-opentype'),
        url('./Styles/Fonts/LabGrotesque-Light.woff2') format('woff2'),
        url('./Styles/Fonts/LabGrotesque-Light.woff') format('woff'),
        url('./Styles/Fonts/LabGrotesque-Light.ttf') format('truetype'),
        url('./Styles/Fonts/LabGrotesque-Light.svg#LabGrotesque-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

body {
    width: 100vw;
    font-family: 'Lab Grotesque' !important;
    font-size: 16px;
    cursor: default;
    -webkit-text-size-adjust:none;
}

.ipadBreak,
.resourcesCard .imgDiv.noImg,
.homePage .loginRow,
.homePage .actionRow.three {
    display: none;
}

.desktopBreak {
    display: inline;
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 10px; 
}

a,
a:hover,
a:focus {
    color: inherit;
}

.light { /* light font weight */
    font-weight: 300;
}

.normalWeight {
    font-weight: normal;
}

.xs-semi-bold { /* small text bold */
    font-weight: bold;
}

.bold {
    font-weight: 600;
}

.leftAlign {
    text-align: left;
}

.color-w {
    color: white;
}

.color-b {
    color: #24252b;
}

.color-p {
    color: rgb(204, 0, 255);
}

.color-pk {
    color: rgb(211, 33, 161);
}

.color-o {
    color: rgb(245, 166, 35);
}

.color-g {
    color: rgb(126, 211, 33);
}

.color-gy {
    color: rgb(190, 195, 203);
}

.uppercase {
    text-transform: uppercase;
}

.noMargin {
    margin: 0em;
}

.centered {
    text-align: center;
}

.btn {
    white-space: normal;
    font-weight: 300;
}

.btn:active {
    background-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn.active.focus, 
.btn.active:focus, 
.btn.focus, 
.btn:active.focus, 
.btn:active:focus, 
.btn:focus {
    outline: none !important;
}

.form-control {
    box-shadow: none;
}

textarea {
    resize: none !important;
}

textarea:focus {
    outline: none !important;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rowEnd {
    justify-content: flex-end;
}

.rowBetween {
    justify-content: space-between;
}

.rowCentered {
    justify-content: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.columnBetween {
    justify-content: space-between;
}

.flexOne {
    flex: 1;
}

.pointer {
    cursor: pointer !important;
}

.ReactModal__Overlay {
    background-color: rgba(36, 37, 43, 0.9) !important;
    z-index: 1000 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ReactModal__Content {
    width: 57vw;
    height: 90vh;
    background-color: white;
    border-radius: 0.4rem;
    padding: 2.4em 4.8em 4.2em;
    outline: none;
}

.requestAccess input:-webkit-autofill,
.requestAccess input:-webkit-autofill:hover, 
.requestAccess input:-webkit-autofill:focus,
.requestAccess textarea:-webkit-autofill,
.requestAccess textarea:-webkit-autofill:hover,
.requestAccess textarea:-webkit-autofill:focus,
.requestAccess select:-webkit-autofill,
.requestAccess select:-webkit-autofill:hover,
.requestAccess select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid white;
  -webkit-text-fill-color: white;
  box-shadow: 0 0 0px 1000px #24252b inset;
  -webkit-box-shadow: 0 0 0px 1000px #24252b inset;
  transition: background-color 5000s ease-in-out 0s;
}


/* Homepage NAVBAR ******/
.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
}

.navbar .box {
    width: 14.5em;
    height: 100%;
    color: white;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    padding: 1em 0em 0.4em 2em;
    margin-left: 4.5em;
}

.navbar .box.white {
    background-color: rgba(255,255,255, 0.1);
}

.navbar .box.blue {
    background-color: #24252b;
}

.navbar .title {
    margin-bottom: 0.3em;
}

.navbar .subTitle {
    font-size: 0.65em; 
}




/* SIDEBARS ******/
.homeSidebar .inner,
.fooSidebar,
.leftSideBar {
    width: 95vw;
    position: fixed;
    top: 45%;
    left: 0em;
    z-index: 2;
}

.homeSidebar .inner {
    top: 8em;
}

.fooSidebar,
.leftSideBar {
    width: 3.8em;
}

.leftSideBar {
    top: 10rem;
}

.homeSidebar .logoWrapper,
.leftSideBar .logoWrapper {
    width: 3.8em;
    height: 2.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #24252b;
    border-bottom: 1px solid #24252b;
    margin-bottom: 1.7em;
    cursor: pointer;
}

.homeSidebar .logoWrapper {
    margin-bottom: 1em;
    margin-left: -1em;
}

.homeSidebar .logoWrapper.white {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}

.homeSidebar .logoWrapper.blue {
    border-top-color: #24252b;
    border-bottom-color: #24252b;
}


.homeSidebar ul,
.fooSidebar ul,
.leftSideBar ul {
    z-index: 3;
    padding: 0em;
    cursor: pointer;
    list-style-type: none;
}

.nav>li>a:focus, 
.nav>li>a:hover,
.nav-pills>li.active>a, 
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover,
a:focus,
a:hover {
    background-color: rgba(0,0,0,0);
    outline: none;
    /* outline-color: rgba(255,255,255,0); */
    /* outline-width: 0px; */
    text-decoration: none;
}

.leftSideBar .barsLink {
    cursor: pointer;
}

.homeSidebar .anchors a,
.fooSidebar .wrappers,
.leftSideBar .wrappers {
    height: 1.2em;
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0em;
    cursor: pointer;
}

.homeSidebar .anchors.top a {
    height: auto;
}

.fooSidebar .wrappers {
    cursor: default;
}

.homeSidebar .colors,
.fooSidebar .colors,
.leftSideBar .colors {
    width: 3em;
    height: 0.6em;
    background-color: rgb(190, 195, 203);
    border-top-right-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    margin-left: -3em;
    -webkit-transition: margin-left 0.5s ease-out;
    -moz-transition: margin-left 0.5s ease-out;
    -o-transition: margin-left 0.5s ease-out;
    transition: margin-left 0.5s ease-out;
}

.leftSideBar .colors {
    width: 100%;
    margin-left: -2.5em;
    -webkit-transition: margin-left 0.3s ease-out;
    -moz-transition: margin-left 0.3s ease-out;
    -o-transition: margin-left 0.3s ease-out;
    transition: margin-left 0.3s ease-out;
}

#seeMySelections .leftSideBar .ul:hover .colors {
    margin-left: 0em;
}

.homeSidebar .anchors:hover .colors,
.homeSidebar .anchors.active .colors {
    margin-left: -1.7em;
}
/* 
.homeSidebar .colors.green,
.fooSidebar .colors.green {
    background-color: rgb(126, 211, 33);
}

.homeSidebar .colors.orange,
.fooSidebar .colors.orange {
    background-color: rgb(245, 166, 35);
}

.homeSidebar .colors.purple,
.fooSidebar .colors.purple {
    background-color: rgb(204, 0, 255);
} */

.homeSidebar .colors.grey,
.leftSideBar .colors.grey {
    background-color: #24252b;
}

.homeSidebar .colors.white,
.leftSideBar .colors.white {
    background-color: #ffffff;
}

.homeSidebar .colors.blue {
    background-color: #24252b;
}

.homeSidebar .titles {
    font-size: 1em;
    margin: 0em 0.5em;
    line-height: 1;
    color: rgba(0,0,0,0);
    line-height: 0.76;
    letter-spacing: 0.4px;
    font-weight: 300;
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;
}

/* .homeSidebar .titles.white {
    color: #ffffff;
}

.homeSidebar .titles.blue {
    color: #24252b;
} */

/* .homeSidebar .titles.long {
    align-self: flex-start;
    margin-top: 0.1em;
} */

/* .homeSidebar .anchors.green:hover .titles,
.homeSidebar .anchors.green.active .titles {
    color: rgb(126, 211, 33);
}

.homeSidebar .anchors.orange:hover .titles,
.homeSidebar .anchors.orange.active .titles {
    color: rgb(245, 166, 35);
}

.homeSidebar .anchors.purple:hover .titles,
.homeSidebar .anchors.purple.active .titles {
    color: rgb(204, 0, 255);
} */

.homeSidebar .anchors.white:hover .titles,
.homeSidebar .anchors.white.active .titles {
    color: #ffffff;
}

.homeSidebar .anchors.blue:hover .titles,
.homeSidebar .anchors.blue.active .titles {
    color: #24252b;
}






/* HOMEPAGE ******/
.homePage {
    height: 100%;
}

.homePage .index4 {
    z-index: 4;
}

.homePage .row {
    margin: 0em; /* sidebar making elements go past 100vw */
}

.homePage .container-fluid {
    padding: 0em;
}

.homePage .sections {
    min-height: 100vh;
    height: 100%;
    padding: 5.5em 0em;
}



/* Homepage - Section 1 */
.homePage .section1 {
    background-color: #24252b;
    color: white;
    padding-top: 4.5em;
}

.homePage .actionRow.one {
    height: 6em;
}

.homePage .actionBtnWrap .index4 {
    padding: 0em 5.75em;
}

.homePage .actionBtn {
    width: auto;
    float: right;
    cursor: pointer;
}

.homePage .actionRow.two {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.homePage .actionCol {
    padding: 0em 5.75em;
}

.homePage .actionCol.one {
    padding-right: 2em;
    /* margin-top: -2em; */
}



.homePage .requestSubTitle {
    font-size: 2em;
    font-weight: 300;
    line-height: 1.5;
}

.homePage .requestTitle,
.homePage .loginTitle {
    font-size: 1.2em;
}

.actionRow .form-group {
    margin: 0em;
}

.actionInput {
    width: 100%;
    height: 3.5em !important;
    font-size: 1.1em !important;
    font-weight: 300 !important;
    letter-spacing: 0.4px !important;
    text-align: left !important;
    background-color: rgba(0,0,0,0) !important;
    padding: 0em !important;
    margin: 0em !important; 
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    border-radius: 0em !important;
    border-left: 0em !important;
    border-right: 0em !important;
    border-top: 0em !important;
}

.actionInput::placeholder {
    font-weight: 300 !important;
    letter-spacing: 0.4px !important;
}

.actionInput.white {
    color: white;
    border-bottom-color: white;
}

.actionInput.white::placeholder {
    color: white;
}

.actionInput.white.btn:active {
    background-color: rgba(0,0,0,0);
    color: white;
}

.actionInput.blue {
    color: #24252b;
    border-bottom-color: #24252b;
}

.actionInput.blue::placeholder {
    color: #24252b;
}

.actionInput.blue.btn:active {
    background-color: rgba(0,0,0,0);
    color: #24252b;
}

.actionInput.white.error,
.actionInput.blue.error {
    color: #f80606;
    border-bottom-color: #f80606;
}

.form-control:focus {
    border-color: inherit;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.homePage .industryWrapper {
    position: relative;
}

.homePage .actionInput.industry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1em;
    padding-left: 10px !important;
}

.homePage .industryArrow {
    width: 1em;
    height: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-radius: 50%; */
    /* border: 1px solid magenta; */
}

.homePage .industryArrow .glyphicon {
    font-size: 1em;
}

.homePage .industryOpenDiv.closed {
    opacity: 0;
    z-index: -1;
}

.homePage .industryOpenDiv {
    z-index: 6;
    opacity: 1;
    width: calc(100% + 2.5em);
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    color: #24252b;
    padding: 2em 0em 2.4em;
    border-radius: 0.4rem;
    box-shadow: 0.1em 0.1em 0.3em rgba(37,37,37,0.1);
}

.homePage .industryOpenDiv .title,
.homePage .industryOpenDiv .options {
    padding-left: 2.5em; /* Same as .textInput margin-left */
}

.homePage .industryOpenDiv .options {
    width: 100%;
    height: 2.5em;
    /* margin-top: 1.5px; */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0em;
}

.homePage .industryOpenDiv .options label {
    width: 100%;
    padding-left: 0em;
}

.homePage .industryOpenDiv .options:hover,
.homePage .industryOpenDiv .options.active {
    background-color: rgb(211, 33, 161);
    color: white;
}

.homePage .industryOpenDiv .textInput {
    width: 70%;
    height: 2.5em;
    font-size: 1em;
    border: none;
    border-bottom: 1px solid rgb(22, 39, 67, 0.7);
    border-radius: 0em;
    margin-left: 2.5em; /* Same as .title & .options padding-left */
    padding-left: 0em;
    box-shadow: none;
}

.homePage .industryOpenDiv .textInput::placeholder {
    color: #24252b;
}


.toolTips.forRequestAccess {
    width: 22em !important;
    position: absolute !important;
    margin-top: 0.5em;
}

.toolTips.forRequestAccess .tooltip-inner {
    max-width: 100% !important;
}

.toolTips.forRequestAccess p {
    font-size: 0.8em;
}

#passwordTooltip p {
    color: #f60606;
}

.tooltip.top .tooltip-arrow {
    border-top-color: white;
    border-left-color: rgba(0,0,0,0) !important;
}

#emailHoverTool.top .tooltip-arrow,
#accessDisabledHoverTool.top .tooltip-arrow {
    left: 2.5em !important;
}

#accessDisabledHoverTool {
    width: auto !important;
    margin-top: -0.2em;
    margin-left: 3.7em;
}

#email, #password, #organization, #name, #citystate, #country, #tempPassword, #newPassword {
    padding: 10px !important;
}

.homePage .requestAccessBtn,
.homePage .signInBtn,
.ResetPassword .passwordResetBtn,
.signInAndAgree .signInAgreeBtn {
    min-width: 12.5em;
    /* width: 100%; */
    font-size: 0.75em;
    background-color: rgb(211, 33, 161);
    color: white;
    border: none;
    padding: 1em 2em;
    margin-top: 2.5em;
}

/* .homePage .applyIndustryBtn {
    width: 8em;
    padding: 0.7em;
    margin-top: 2em;
}

.homePage .applyIndustryBtn:active {
    background-color: rgba(211, 33, 161, 1);
    color: white;
} */


.homePage .requestAccess .title {
    cursor: pointer;
}

.homePage .requestAccessBtn {
    margin-top: 4.5em;
}

.homePage .requestAccessBtn.disable,
.homePage .signInBtn.disable {
    opacity: 0.45;
    cursor: not-allowed;
}

.homePage .requestAccessBtn:active,
.homePage .requestAccessBtn.disable:hover,
.homePage .signInBtn:active,
.ResetPassword .passwordResetBtn:active,
.signInAndAgree .signInAgreeBtn:active {
    background-color: rgba(211, 33, 161, 0.7);
    color: white;
}

.homePage .forgotPassword {
    font-size: 0.68em;
    text-align: right;
    margin-top: 2em;
    cursor: pointer;
}

.homePage .signInBtn {
    margin-top: 6.4em;
}





/* Reset Password */
#resetPasswordWrapper .innerPage {
    min-height: 95vh;
    padding-top: 11em;
    margin-bottom: 0em;
}

.ResetPassword .form {
    width: 50%;
}

.ResetPassword .actionInput {
    height: 2em !important;
    margin-bottom: 2em !important;
}

.ResetPassword .actionInput.code {
    margin-bottom: 0.5em !important;
}

.ResetPassword .passwordResetBtn {
    font-size: 1em;
    min-width: 10em;
    width: 10em;
    height: 2.5em;
    padding: 0em;
}

.ResetPassword .passwordResetBtn[disabled]:hover {
    background-color: rgb(211, 33, 161);
}

.ResetPassword .pLinks {
    color: rgb(211, 33, 161);
}






/* HomePage - #about */
.homePage #about {
    position: relative;
    height: 100vh;
    /* background-color: rgba(166, 202, 202, 0.5); */
    /* display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center; */
}

.homePage .sections.about {
    padding: 0em;
}

.carousel {
    height: 100%;
}

.carouselItem {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
}

.carousel-caption {
    text-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 0em 0.93em; */
    top: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    right: 0;
    left: 0;
}

.carousel-1 {
    background-image: url('./Styles/Images/beautyForFreedom-1.JPG');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.carousel-2 {
    background-image: url('./Styles/Images/beautyForFreedom-2.JPG');
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
}

.carouselItem .flexRow {
    width: 100%;
}

.homePage .partners {
    width: 30vw;
    background-color: white;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    padding: 2em 4.5em 1.4em 2.8em;
}

.homePage .partners .title {
    font-size: 1.25em;
    text-align: left;
    margin: 0em 0em 1em;
    line-height: 1.62;
    letter-spacing: 0.2px;
}

.homePage .partners .logoDivs {
    width: 100%;
    height: 5.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.homePage .partners .logos {
    width: 7.5em;
}

.homePage .partners .partner2 {
    width: 100%;
    border-top: 1px solid #24252b;
    border-bottom: 1px solid #24252b;
    /* padding-right: 3em; */
}

.homePage .partners .partner2 .logos {
    width: 9em;
}

/* .homePage .viewPartnersBtn {
    width: 2.2em;
    height: 2.2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(211, 33, 161);
    border: none;
    padding: 0em;
    cursor: pointer;
} */

/* .homePage .shapeGlyph {
    height: 1.1em;
    border-radius: 50%;
    color: white;
    font-size: 1.1em;
} */

.homePage .credits {
    position: absolute;
    bottom: 0.5em;
    left: 0;
    right: 0;
    margin: 0% auto;
    padding-left: 13rem;
}

.homePage .credits .firstTitle {
    font-size: 0.6em;
    line-height: 2.15;
    letter-spacing: 0.8px;
}

.homePage .creditIcon {
    height: 0.85em;
    margin-left: 0.5em;
    cursor: pointer;
}

.homePage .openCredits {
    width: 30em;
    padding: 1.4em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0% auto;
    /* margin-right: 1em; */
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.4rem;
}

.homePage .openCredits .rowOne {
    padding-bottom: 0.8em;
}

.homePage .openCredits .creditsTitle {
    font-size: 1em;
}

.homePage .openCredits .closeCreditIcon {
    height: 1em;
    cursor: pointer;
}

.homePage .openCredits .body,
.homePage .openCredits .website {
    font-size: 0.8em;
    line-height: 1.63;
    letter-spacing: 0.4px;
}




/* Homepage - #dna */
.homePage #dna {
    background-color: #1e1f26; /* rgb(31, 32, 39) ? darker: #1f2027, first: #24252b */
    background-image: url('./Styles/Images/dna-of-indicator(1).svg');
    background-size: auto 80%;
    background-position: center;
    background-repeat: no-repeat;
}




/* Homepage - #Resources & Resources Cards */
.homePage #resources .row {
    width: 69vw;
    margin: 0% auto;
}

.homePage #resources .col {
    padding: 0em;
}

.homePage #resources .titleCol {
    margin-bottom: 2.5em;
    padding: 0em;
}

.resourcesContainer {
    width: 100%;
    height: 100%;
    z-index: 4;
    display: flex; 
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between; 
    padding: 0em;
}

.resourcesWrappers {
    width: 48.5%;
}

.resourcesCard {
    width: 100%;
    border-radius: 0.4rem;
    overflow: hidden;
    box-shadow: 0.1em 0.1em 0.3em rgba(37,37,37,0.1); /* keep .text.hasImg border as same color */
    margin-bottom: 1.5em;
}

.resourcesCard .imgDiv {
    width: auto;
}

.resourcesCard .text {
    padding: 1.5em 2em;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}

.resourcesCard .text.hasImg {
    /* border-left: 0.05rem solid rgba(37,37,37,0.1);
    border-right: 0.05rem solid rgba(37,37,37,0.1);
    border-bottom: 0.05rem solid rgba(37,37,37,0.1); */
    background-color: #f7f7f7;
}

.resourcesCard .text.noImgBlue {
    background-color: #24252b;
    color: white;
    border-radius: 0.4rem;
}

.resourcesCard .title {
    font-size: 1em;
    /* text-transform: uppercase; */
}

.resourcesCard .siteName {
    font-size: 0.85em;
}



.resourcesCard .text.noImgBlue p.color-b,
.resourcesCard .text.noImgBlue p.color-g {
    color: white;
}





/* ALL INNER PAGE DIVS!! ********/
.innerPage {
    z-index: 4;
    width: 69vw;
    /* min-height: calc(100vh - 26.5em); */
    min-height: 75vh;
    position: relative;
    padding-left: 0em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.8em;
    margin-bottom: 6.5em;
}





/* Resources Page */
.resourcesPage .section1 {
    z-index: 4;
    padding: 11.2em 0em 23em;
}

.resourcesPage .section1 .row {
    width: 69vw;
    margin: 0% auto;
}

.resourcesPage .section1 .col {
    padding: 0em;
}





/* Supporting Organizations */
.supportingOrgs .title {
    margin-bottom: 2.5em;
}

.supportingOrgs .orgsCard  {
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    box-shadow: 0.1em 0.1em 0.4em 0em rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    margin-bottom: 1.4em;
    z-index: 6;
}

.supportingOrgs .orgsCard .wrapperLink {
    width: 100%;
    min-height: 9.1em;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.supportingOrgs .orgsCard .logoDiv {
    width: 16.3em;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.supportingOrgs .orgsCard .orgLogos {
    width: 100%;
    height: auto;
    flex: 1.5;
}

.supportingOrgs .orgsCard .logoDiv .divider {
    width: 0.1em;
    height: 82%;
    float: right;
    border-right: 1px solid #bec3cb;
}

.supportingOrgs .orgsCard .details {
    width: 100%;
    padding: 1.9em 1.9em 0.96em;
}

.supportingOrgs .orgsCard .details .text {
    margin-bottom: 1.9em;
}





/* Sign In and Agree Page */
.signInAndAgree .pageTitle {
    margin-bottom: 1.7em;
}

.signInAndAgree .mainText {
    width: 50%;
    margin-bottom: 2em;
}

.signInAndAgree .form {
    width: 50%;
} 

.signInAndAgree .agreeModalLinks {
    cursor: pointer;
}

.signInAndAgree .options {
    margin-top: 2em;
}

.signInAndAgree .options .key {
    font-size: 0.8em;
    line-height: 1.5;
    letter-spacing: 0.4px;
}

.signInAndAgree .signInAgreeBtn {
    margin-top: 4em;
}

.signInAndAgree .signInAgreeBtn span {
    line-height: 1.31;
    letter-spacing: 0.6px;
}





/* About Us Page */
#aboutUs .title,
.faqPage .title,
.supportingOrgs .title,
.innerModal.policyModal .title,
.innerModal.termsModal .title {
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: 0.3px;
    margin-bottom: 2.5em;
}

#aboutUs .bodyText,
.faqPage .bodyText,
.innerModal.policyModal .bodyText,
.innerModal.termsModal .bodyText {
    line-height: 1.62;
    letter-spacing: 0.4px;
}






/* RIGHT USER SIDE BAR ******/
.rightSideBar {
    width: 3.8em;
    /* height: 100vh; */
    height: auto;
    top: 15.7rem;
    position: fixed;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rightSideBar .buttonHolders {
    width: 3.8em;
    height: 3.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #9b9b9b;
    /* border-bottom: 0.1em solid#9b9b9b; */
}

.rightSideBar .buttonHolders.bottom { 
    border-bottom: 1px solid #9b9b9b;
}

.toolTips {
    /* width: 12.6em;
    max-width: 12em !important; */
    z-index: 3100;
    font-size: 1em;
    margin-right: 1em;
    position: fixed;
    font-family: 'Lab Grotesque';
}

#profileTooltip {
    top: 13.7rem !important;
}

#notificationsTooltip {
    top: 19rem !important;
}

#notificationsTooltip.zeroNew {
    margin-top: 1.5rem !important;
}

#notificationsTooltip.forRightSideBar.tooltip.left  .tooltip-arrow {
    top: 6rem !important;
}

#notificationsTooltip.forRightSideBar.tooltip.left.zeroNew .tooltip-arrow {
    margin-top: -2rem !important;
}

#addIndicatorTooltip {
    /* top: 19.3em !important; */
    top: 28.4rem !important;
}

#bookmarksTooltip {
    top: 34.5rem !important;
}

/* #settingsTooltip {
    top: 25em !important;
} */

.tooltip.in{
    opacity: 1;
}

.tooltip.left {
    margin-left: -0.8em;
}

.toolTips .tooltip-inner {
    /* width: 12em; */
    max-width: 100% !important; 
    padding: 0.96em 0.96em 0.7em 0.96em;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0.1em 0.1em 0.4em 0em rgba(0, 0, 0, 0.1);
    text-align: left;
}

.toolTips .toolTitle {
    font-weight: 500;
}

.toolTips.forRightSideBar .pinkToolLinks {
    font-size: 0.8em;
    margin: 0em;
    display: inline-block !important;
    background-color: rgba(0,0,0,0);
    padding: 0em;
    border: none;
}

.toolTips.forRightSideBar .pinkToolLinks.bottom {
    margin-top: 0.5em;
}

.toolTips .pinkToolLinks:hover,
.toolTips .pinkToolLinks:active,
.toolTips .pinkToolLinks:focus {
    color: rgb(211, 33, 161);
    background-color: rgba(0,0,0,0);
}

.toolTips .pinkToolLinks:hover {
    font-weight: bold;
}

.toolTips .tooltip-arrow {
    border-left-color: #ffffff !important;
}

.toolTips .tooltip-arrow:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    height: 10px;
    width: 10px;
    bottom: -5px;
    left: -12px;
    background: rgba(0,0,0,0);
    transform: rotate(45deg);
    box-shadow:  0.1em 0.1em 0.4em 0em rgba(0, 0, 0, 0.1);;
    border-radius: 0.01em;
}
/* end: tooltips */

.rightSideBar .transparentBtns {
    width: 1.44em;
    height: 1.5em;
    font-size: 1em;
    background-color: rgba(0,0,0,0);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0em;
    border: none;
}

.toolTips .transparentBtns {
    border: none;
}

.toolTips .pinkToolLinks.btn-default {
    padding: 0em;
}

.rightSideBar .transparentBtns:active,
.toolTips .transparentBtns:active,
.toolTips .pinkToolLinks:hover {
    background-color: rgba(0,0,0,0);
}

.rightSideBar .profileBtn {
    /* width: 1.4em; */
    /* height: 1.4em; */
    /* border-radius: 50%; */
    /* background-color: #9b9b9b; */
    background: url('./Styles/Images/profileImage-inactive.png');
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
}

.rightSideBar .profileBtn:hover,
.rightSideBar .profileBtn:active {
    /* background-color: rgb(211, 33, 161); */
    background: url('./Styles/Images/profileImage-active.png');
}

/* .rightSideBar .profileBtn .initials {
    font-size: 0.6em;
    line-height: 1.5;
    letter-spacing: 0.3px;
} */

.rightSideBar .leftIcons {
    position: absolute;
    top: 0;
    left: 0;
}

.rightSideBar .redCircles.border {
    width: 46%;
    height: 46%;
    background-color: #f7f7f7;
    position: absolute;
    top: -3%;
    right: -3%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rightSideBar .red.innerCircle,
.rightSideBar .greyCircles.innerCircle {
    width: 80%;
    height: 80%;
    background-color: #c20707;
    border-radius: 50%;
}

.rightSideBar .notificationsIcon {
    background: url('./Styles/Images/notifications-inactive.png');
} 

.rightSideBar .notificationsBtn:hover .notificationsIcon {
    background: url('./Styles/Images/notifications-active.png');
    width: 100%;
    height: 100%;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: 0.05em 0.1em;
}

.rightSideBar .notificationsBtn .number {
    font-size: 0.45em;
    line-height: 1.3em;
    letter-spacing: 0.3px;
} 

.rightSideBar .addIndicatorBtn {
    background: url('./Styles/Images/addIndicator-inactive.png');
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
}

.rightSideBar .addIndicatorBtn:hover {
    background: url('./Styles/Images/pinkPlusIcon.png');
}

.rightSideBar .bookmarksIcon {
    background: url('./Styles/Images/bookmarks-inactive.png');
} 

.rightSideBar .bookmarksIcon:hover {
    background: url('./Styles/Images/bookmarks-active.png');
} 

.rightSideBar .leftIcons,
.rightSideBar .leftIcons:hover {/* must come after */
    width: 100%;
    height: 100%;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: 0.05em 0.1em;
}

.rightSideBar .redCircles.border.bm {
    width: 0.53em;
    height: 0.53em;
    /* top: -0.16em;
    right: -0.1em; */
    top: -0.13em;
    right: -0.06em;
}

.rightSideBar .redCircles.border.bm.notifications {
    top: 3% !important;
    right: 3% !important;
}

.rightSideBar .red.innerCircle.bm,
.rightSideBar .grey.innerCircle {
    width: 70%;
    height: 70%;
    border-radius: 50%;
}

.rightSideBar .grey.innerCircle {
    background-color: #9b9b9b;
}

.settingsBtn {
    background: url('./Styles/Images/settings-inactive.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
}

.settingsBtn:hover {
    background: url('./Styles/Images/settings-active.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
}






/* DASHBOARD/WORKBENCH *****/
#dashboard,
#seeAllIndicators,
#seeMySelections,
#notificationsPage,
#bookmarks {
    background-color: #f7f7f7;
}

.pageTitle {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 1.24;
    letter-spacing: 0.3px;
    margin-bottom: 1em; /* 2.2em */
}


#dashboard .innerPage {
    min-height: 83vh;
}

/* #dashboard .innerPage.extraSpace {
    margin-bottom: 40em;
} */

/* #dashboard .dashboardInner.blur {
    opacity: 2;
}  */

#dashboard .dropDownsContainer {
    margin-left: -0.5em;
    margin-bottom: 1.4em;
}

#dashboard .dropdown {  
    width: 31.5%;   /* 17.8em */
    display: inline-block;
    margin: 0.5em;
}

#dashboard .dropdown.open .caret {
    -webkit-transform: rotate(-180deg) !important;
    transform: rotate(-180deg) !important;
    color: #24252b;
}

#dashboard .dropDownBtns {
    font-size: 1em;
    width: 100%;
    height: 3.2em;
    background-color: #24252b;
    color: white;
    padding-left: 1.4em;
    padding-right: 1.4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
}

#dashboard .dropdown.open .dropDownBtns {
    background-color: white;
    color: #24252b;
    border-bottom-left-radius: 0em;
    border-bottom-left-radius: 0em;
    box-shadow: none;
}

#dashboard .dropdown.open .dropDownBtns:after {
    content: '';
    width: calc(100% - 2.8em);
    display: block;
    position: absolute;
    bottom: 0em;
    border: 0.5px solid #24252b;
}

#dashboard .dropdown .dropdown-menu {
    width: 100%;
    padding: 1.4em 0em;
    margin-top: 0em;
    border-top-left-radius: 0em;
    border-top-right-radius: 0em;
    box-shadow: none;
    border: none;
}

.selectAllWrap {
    width: 100%;
    padding: 0em 1.4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#dashboard .options.dd,
.signInAndAgree .options,
.categoriesPanel .options {
    height: auto;
    cursor: pointer;
    padding-left: 1.4em;
    margin: 1em 0em;
}

#dashboard .options.dd {
    margin: 1em 0em 0em 1.4em; 
}

#dashboard .options.dd.checkbox label,
.signInAndAgree .options.checkbox label,
.categoriesPanel .options.checkbox label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2em;
    padding-right: 1.5em;
}

#dashboard .options.dd.checkbox label {
    padding-left: 0.5em;
}

.indicatorForm .options.checkbox label{
    padding-left: 1.5em;
}

.categoriesPanel .options.checkbox label {
    padding-left: 0.3em;
}

.signInAndAgree .options.checkbox label {
    padding-left: 0em;
}

.onlyTextLink  {
    opacity: 0;
    font-size: 0.7em;
    /* align-self: flex-start; */
}

#dashboard .options.dd:hover .key,
#dashboard .options.dd.checked .key,
.categoriesPanel .options:hover .key,
.categoriesPanel .options.checked .key {
    color: rgb(211, 33, 161); 
}

#dashboard .options.dd:hover .onlyTextLink,
.categoriesPanel.open .options:hover .onlyTextLink {
    opacity: 1;
}

#dashboard .options.dd:hover .checkmark,
.signInAndAgree .options:hover .checkmark,
.categoriesPanel .options:hover .checkmark {
    border-color: rgb(211, 33, 161);
}




#dashboard .seeSelectionBtn,
#profile .saveProfileBtn {
    width: 100vw;
    height: 4em;
    font-size: 1em;
    text-align: left;
    background-color: #24252b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2em 0em 1.5em;
    margin-left: -15.5vw;
    border-radius: 0em;
    border: none;
}

#dashboard .seeSelectionArrow,
#profile .saveProfileArrow {
    height: 1.5em;
    margin-left: 0.6em;
}



#dashboard .seeAllWrapper {
    width: 100%;
    justify-content: flex-end;
    padding-right: 0.5em;
    margin-bottom: 0.6em;
}

#dashboard .seeAllBtn {
    font-size: 1em;
    border: none;
    background-color: rgba(0,0,0,0);
    margin-right: 0.5em;
}

#dashboard .seeAllBtn:hover .text {
    color: rgb(211, 33, 161)
}

#dashboard .seeAllBtn .seeAllArrow {
    width: 1.5em;
    height: 1em;
    background: url('./Styles/Images/darkRightArrow.png');
    background-size: auto 100% !important;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    margin-left: 0.6em;
}

#dashboard .seeAllBtn:hover .seeAllArrow {
    background: url('./Styles/Images/pinkRightArrow.png');
}

#dashboard .smallSortRow {
    margin-top: 3em;
}

#dashboard .sortTypeWrapper .light {
    margin-bottom: 1em;
}

#dashboard .sortTypeBtns {
    min-width: 7.8em;
    font-size: 0.77em;
    color: white;
    border-radius: 2rem;
    letter-spacing: 0.1px;
    font-weight: 300;
    margin-right: 0.75em;
    cursor: pointer;
    border: none;
}

#dashboard .sortTypeBtns.t,
#t.typePills {
    background-color: #7d9917;
}

#dashboard .sortTypeBtns.d,
#d.typePills {
    background-color: #466f8d;
}

#dashboard .sortTypeBtns.b,
#b.typePills {
    background-color: #69308e;
}

.typePills.inactive {
    opacity: 0.3;
}


.smallSortWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    /* padding: 0em 2.1em; */
    margin-bottom: 1.2em;
}


.sortByGroup {
    position: relative;
    margin: 0em 0.8em;
}

.sortByDropDown,
.sortByDropDown:hover,
.sortByDropDown:focus {
    font-size: 0.8em;
    height: 1em;
    color: #9b9b9b !important;
    background-color: rgba(0,0,0,0) !important;
    padding: 0em;
    border-radius: 0em;
    border: none;
    margin-top: 0.4em;
}

.sortByDropDown .caret {
    color: #9b9b9b;
    margin-left: 0.4em;
    -webkit-transform: rotate(-180deg) !important;
    transform: rotate(-180deg) !important;
}

.sortByOpenDiv {
    display: none;
    min-width: 8em;
    background-color: white;
    position: absolute;
    top: -0.8em;
    left: -1.4em;
    padding: 1.4em 1.4em 0.7em;
    border-radius: 0.4rem;
    box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.09);
    border: none;
}

.sortByOpenDiv.open {
    display: block;
    z-index: 15;
}

.sortByOpenDiv .sortName {
    display: block;
    font-size: 0.8em;
    color: #9b9b9b;
    margin-bottom: 1em;
    line-height: 1.31;
    letter-spacing: 0.6px;
    cursor: pointer;
}

.sortByOpenDiv .sortName:hover {
    font-weight: bold;
}


.changeCardLayout {
    font-size: 1em;
    width: 1em;
    height: 1em;
    border: none;
    border-radius: 0em;
    padding: 0em;
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
}

.changeCardLayout.cards {
    background: url('./Styles/Images/layoutCards.png');
}

.changeCardLayout.list {
    background: url('./Styles/Images/layoutList.png');
}



.iCardsContainer {
    width: 100%;
    height: 100%;
    /* margin-left: -0.7em; */
    /* opacity: 1; */
    z-index: 4;
    column-gap: 1.4em;
    /* display: flex;  */
    /* flex-direction: column; */
    /* flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;  */
    padding: 0em;
}  

.iCardsContainer.loading,
.resourcesContainer.loading {
    opacity: 0;
    /* flex-wrap: nowrap; */
}

.iCardsContainer .iCardWrappers {
    width: 100%;
    /* width: 30.5%; */ /* flex column */
    /* width: 17.8em */
    /* margin-left: 0.7em; */
    /* margin-right: 0.7em; */
    padding: 0.7em 0em;
    /* display: inline-table; inline-flex */
    -webkit-column-break-inside: avoid !important;
    page-break-inside: avoid !important;
    break-inside: avoid-column !important;
}

.iCardsContainer .iCardWrappers.listView {
    padding: 0em;
}



#downloadMultipleBtn {
    width: 100vw;
    height: 4em;
    font-size: 1em;
    background-color: #24252b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 7rem;
    border: none;
    border-radius: 0em;
    z-index: 15;
}

#downloadMultipleBtn .icon {
    height: 1.2em;
    margin-left: 0.8em;
}




/* INDICATOR CARDS */
.iCards {
    width: 100%;
    position: relative;
    background-color: white;
    border-radius: 0.4rem;
    /* margin-top: 0.5em; */
    /* margin-bottom: 1.4em; */
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);
    transition: all 0.2s ease-in-out;
    overflow: hidden;
}

.iCards::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
    transition: opacity 0.2s ease-in-out;
}



.iCards:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.iCards:hover::after {
    opacity: 1;
}

.iCardsInner {
    padding: 1.4em 1.4em 1.3em;
}

.iCardModalBtn {
    cursor: pointer;
    padding-right: 2em;
}

.iCards .infoGroup,
.iCardModal .infoGroup {
    margin: 1em 0em;
}

.iCards .infoGroup.rowOne {
    width: 100%;
    justify-content: space-between;
    margin: 0em 0em 0.5em;
}

.iCards .infoGroup.rowTwo {
    padding-bottom: 1em;
    border-bottom: 1px solid #9b9b9b;
    margin: 0em;
}

.iCardModal .infoGroup.rowOne {
    padding-bottom: 1em;
    border-bottom: 1px solid #9b9b9b;
    margin-top: 0em;
}

.iCards .options,
.signInAndAgree .options {
    height: 1.2em !important; 
    cursor: pointer;
    /* padding-left: 1.4em; */
}

.iCards .options:hover,
.signInAndAgree .options:hover {
    background-color: rgba(0,0,0,0) !important;
}

.iCards .options .checkmark,
.signInAndAgree .options .checkmark {
    border-color: #9b9b9b !important;
}

.iCards .options .checkmark:hover {
    border-color: rgb(211, 33, 161) !important;
}

.iCards .options.checked .checkmark,
.signInAndAgree .options.checked .checkmark {
    border-color: rgb(211, 33, 161) !important;
}

.iCards p,
.iCardModal p,
.toolTips.foriCards p {
    margin-bottom: 0em;
}

.iCards .smallText,
.iCardModal .smallText,
.toolTips.foriCards .smallText,
#profile .smallText {
    font-size: 0.7em;
    font-weight: 300;
    letter-spacing: 0.4px;
}

.iCardModal .smallText {
    font-size: 0.9em;
}

.iCards .infoText,
.iCards .summaryText,
.iCardModal .infoText,
.toolTips.foriCards .infoText {
    line-height: 1.56;
    letter-spacing: 0.6px;
}

.iCards .summaryText {
    font-size: 0.9em;
}

.iCards .infoText {
    font-size: 0.8em;
}



.iCards .bottomActions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.3em;
}

.typePills {
    font-size: 1em;
    min-width: 2.8em;
    min-height: 1.3em;
    border-radius: 1rem;
    border-width: 0em;
    border-color: rgba(0,0,0,0);
    margin-right: 0.3em;
    justify-content: center;
    cursor: default;
    padding: 0em;
}

.largeBtn .typePills {
    padding: 0.35em 1em;
    border-radius: 2rem;
}

.typePills span {
    font-size: 0.6em;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.largeBtn .typePills span {
    font-size: 0.7em;
    font-weight: 400;
}

/* .typePills tooltips */
.iTypeTooltip {
    font-family: 'Lab Grotesque';
    z-index: 2100;
}

.iTypeTooltip .tooltip-inner {
    border-radius: 2rem;
}
.iTypeTooltip .tooltip-inner p {
    letter-spacing: 0.3px;
}

#iTypeTooltip-t .tooltip-inner {
    background-color: #7d9917;
}
#iTypeTooltip-t .tooltip-arrow  {
    border-bottom-color: #7d9917;
}

#iTypeTooltip-d .tooltip-inner {
    background-color: #466f8d;
}
#iTypeTooltip-d .tooltip-arrow  {
    border-bottom-color: #466f8d;
}

#iTypeTooltip-b .tooltip-inner {
    background-color: #69308e;
}
#iTypeTooltip-b .tooltip-arrow  {
    border-bottom-color: #69308e;
}




.iCards .iconOuter {
    width: 1.9em;
    height: 1.9em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iCards .bottomIconBtn {
    width: 1.5em;
    height: 1.5em;
    padding: 0em;
    margin: 0em;
    border: none;
    border-radius: 0em;
}

.iCards .downloadLink {
    width: 1.2em;
    height: 1.2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iCards .download {
    background: url('./Styles/Images/download-inactive.png');
    background-size: auto 100% !important;
    background-repeat: no-repeat !important;
}
.iCards .download:hover {
    background: url('./Styles/Images/pinkDownloadBtn.png');
}

.iCards .addBookmark {
    background: url('./Styles/Images/bookmark-inactive.png');
    background-size: auto 98% !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
}
.iCards .addBookmark:hover,
.iCards .addBookmark.active {
    background: url('./Styles/Images/pinkBookmark.png');
    box-shadow: none;
}

.iCards .otherInfo {
    background: url('./Styles/Images/cardsMore-inactive.png');
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
    background-position: center 90% !important;
}
.iCards .otherInfo:hover {
    background: url('./Styles/Images/cardsMore-active.png');
}


/* iCARDS OTHER INFO TOOLTIPS */
.toolTips.foriCards {
    max-width: 18em;
    position: absolute !important;
}

.tooltip.foriCards.right .tooltip-arrow {
    border-right-color: #f5f5f5;
}

.toolTips.foriCards .tooltip-arrow:before {
    left: 0.15em !important;
}

.toolTips.foriCards .tooltip-inner {
    width: 100%;
    max-width: 18em !important;
    background-color: #f5f5f5;
    padding: 1.5em 2em 0.5em 1.5em;
}

.toolTips.foriCards .infoSections {
    margin-bottom: 0.5em;
}



/* iCards list view */
.iCards.listView {
    margin: 0px 0px 8px;
}

.iCards.listView .iCardsInner,
.iCards.listView .iCardModalBtn {
    display: flex !important;
    flex-direction: row !important;
}

.iCards.listView .cardTitles {
    display: none;
}

.iCards.listView .iCardModalBtn {
    width: 100%;
    padding: 0em;
}

.iCards.listView .infoGroup {
    margin: 0em;
}

.iCards.listView .infoGroup.rowOne {
    width: 10%;
    align-items: flex-start;
}

.iCards.listView .checkbox {
    margin: 0.3em 0em 0em;
}

.iCards.listView .infoGroup.rowTwo {
    width: 62%;
    padding-right: 4%;
    border-bottom: none;
}

.iCards.listView .infoGroup.date,
.iCards.listView .infoGroup.source {
    width: 19%;
    padding-right: 3%;
}

.iCards.listView .bottomActions {
    width: 32%;
    margin: 0em;
}

.iCards.listView .flexRow.classes {
    flex-direction: column;
}

.iCards.listView .flexRow.classes .typePills {
    margin-bottom: 0.2em;
}

.iCards.listView .flexRow.actionBtns {
    align-items: flex-start;
}

.iCards.listView .iconOuter {
    height: auto;
}

.iCards.listView .otherInfo {
    background-position: center 0% !important;
}

.iCards.listView .listViewMoreInfo {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: row;
    padding: 0em 1.4em 0em 9.2%;
    transition: all ease 0.5s; 
}

.iCards.listView .listViewMoreInfo.open {
    max-height: 600px;
    padding: 1.4em 1.4em 1.3em 9.2%; 
}

.iCards.listView .listViewMoreInfo .col.one {
    width: 35%;
    padding-right: 3%;
}

.listViewMoreInfo .infoGroup {
    margin-bottom: 0.5em;
}

.listViewCardTitles .item {
    padding-right: 2%;
}

.listViewCardTitles .selectAll {
    width: 9.2%;
}

.listViewCardTitles .summary {
    width: 41.4%;
}

.listViewCardTitles .date {
    width: 12.8%;
}

.listViewCardTitles .source {
    width: 12.6%;
}

.listViewCardTitles p {
    font-size: 0.7em;
}



/* iCards pagination */
.pageNumberWrapper {
    text-align: center;
    margin-top: 3.4em;
    margin-left: -1.4em;
}

.pagination {
    font-size: 0.8em;
    margin: 0em;
}

.pagination .pageBtns a,
.pagination .pageBtns.active span {
    width: 2.8em;
    height: 2.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    margin: 0em 0.4em;
}

.pagination .pageBtns a {
    background-color: rgba(0,0,0,0);
    border-color: #9b9b9b;
    color: #9b9b9b;
}

.pagination .pageBtns.active span,
.pagination .pageBtns a:hover,
.pagination .outerLeft a:hover,
.pagination .outerRight a:hover {
    background-color: #b61c8b;
    color: white;
    border-color: #b61c8b;
}

.pagination .outerLeft a,
.pagination .outerRight a,
.pagination .outerLeft a:focus,
.pagination .outerRight a:focus {
    background-color: white;
    color: #9b9b9b;
    border-color: #9b9b9b;
}

.pagination .outerLeft a {
    margin-left: 0em;
}

.pagination .outerRight a {
    margin-right: 0em;
}





/* iCards Modal ****/
.iCardModal {
    width: 100%;
    height: 100%;
    z-index: 2000;
    position: fixed;
    top: 0em;
    left: 0em;
    background-color: #24252bf1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2em;
}

.iCardModalBody {
    width: 100%;
}

.iCardModal .iCard-innerModal {
    width: 55%; /* same as downloadBtn */
    height: auto;
    max-height: 90vh;
    background-color: white;
    border-radius: 0.4rem;
    /* padding: 3em 3.4em 2.2em; */
    margin: 0% auto;
    overflow: scroll;
}

.iCardModal .mainInfo {
    padding: 0em 3.4em 3em;
}

.iCardModal .bookmarkedIcon {
    width: 0.8em;
    height: 1.1em;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
}

.iCardModal .bookmarkedIcon.true {
    background: url('./Styles/Images/pinkBookmark.png');
}

.iCardModal .bookmarkedIcon.false {
    background: url('./Styles/Images/bookmark-inactive.png');
}

.iCardModal .closeWrapper {
    width: 100%;
    text-align: right;
    margin: 0.5em 0em;
}

.iCardModal .closeIcon {
    height: 1em;
    cursor: pointer;
}

.iCardModal .infoGroup.divider {
    padding-bottom: 1.4em;
}

.iCardModal .divider {
    border-bottom: 1px solid #9b9b9b;
    margin-bottom: 1.4em;
}

.iCardModal .divider.noMargin {
    margin-bottom: 0em;
}

.iCardModal .categoryContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -0.5em;
}

.iCardModal .categories {
    width: 30%;
    display: inline-block;
    margin: 0em 0.5em 1.4em;
}

.iCardModal .docTitle {
    padding-bottom: 0.4em;
    border-bottom: 1px solid #9b9b9b;
}

/* .iCardModal .fileContainer {
    min-height: 3.7em;
    padding: 0em 0.5em;
} */

/* .iCardModal .fileIcon {
    height: 1.4em;
    margin-right: 1em;
} */

.iCardModal .fileDownloadIcon {
    background: url('./Styles/Images/download-inactive.png');
    background-size: auto 100% !important;
    background-repeat: no-repeat;
}

.iCardModal .fileDownloadIcon:hover {
    background: url('./Styles/Images/pinkDownloadBtn.png');
}

.iCardModal .downloadBtn,
.iCardModal .downloadBtn:active {
    width: 55%; /* same as .iCard-innerModal */
    height: 4em;
    font-size: 0.8em;
    color: white;
    padding: 0.9em 2.3em;
    text-align: left;
    background-color: #24252b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 90vh;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0em;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    border: none;
    z-index: 20;
}

.iCardModal .cardDownloadIcon {
    height: 1.3em;
    margin-left: 1em;
}







/* See All Indicators */






/* See My Selections */
.guidanceText {
    font-size: 1.1em;
    line-height: 1.54;
    letter-spacing: 0.5px;
}

.guidanceText.marginB {
    margin-bottom: 1.1em;
}

.seeSelectionsInner.panelClosed {
    position: relative;
    top: 0;
    right: 0;
    transition: position ease-out 0s, right ease-out 0s; 
}

.seeSelectionsInner.panelOpen {
    position: absolute;
    top: 0;
    right: 110vw;
    transition: position ease-out 0s, right ease-out 0s; 
    /* transition-delay: 0.7s; */
    z-index: -1;
} 

#seeMySelections .pillsContainer {
    width: 70%;
    margin: -0.2em 0em 3em -0.2em;
}

#seeMySelections .selectionPills, 
#seeMySelections .addMoreCategories {
    width: auto;
    height: 1.9em;
    font-size: 1em;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0em 1em;
    background-color: #24252b;
    border-radius: 2rem;
    margin: 0.2em;
    border: none;
}

#seeMySelections .selectionPills .sPillsInner {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#seeMySelections .selectionPills .name,
#seeMySelections .addMoreCategories p {
    font-size: 0.8em;
    display: inline-table;
    letter-spacing: 0.05em;
}

#seeMySelections .selectionPills .removeCategory {
    height: 0.7em;
    margin-left: 0.6em;
    cursor: pointer;
}

#seeMySelections .addMoreCategories {
    background-color: #9b9b9b;
}

#seeMySelections .addMoreCategories p {
    color: white;
}

#seeMySelections .addMoreCategories:hover {
    background-color: white;
}

#seeMySelections .addMoreCategories:hover p {
    color: #24252b;
}




/* Sub Categories panel */
.categoriesPanel {
    width: 100vw;
    height: auto;
    margin-left: -110vw;
    background-color: white;
    z-index: 20;
    transition: margin-left ease-in-out 0.7s;
}

.categoriesPanel .leftSideBar {
    left: -100%;
    transition: left ease-out 0.7s;
}

.categoriesPanel.closed {
    position: absolute;
}



.categoriesPanel.open {
    /* display: block; */
    z-index: 5;
    margin-left: 0em;
}

.categoriesPanel.open .leftSideBar {
    left: 0em;
}

.categoriesPanel.open .leftSideBar .colors {
    margin-left: 0em;
}

.categoriesPanel .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.categoriesPanel .pageTitle {
    margin-bottom: 1em;
}

.categoriesPanel .closePanelIcon {
    width: 1em;
    height: 1em;
    padding: 0em;
    background: url('./Styles/Images/pinkClose.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border: none;
}



.categoriesPanel .catRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin: auto; */
}

.categoriesPanel .catColumns {
    width: 31.5%;
}

.categoriesPanel .wholeCategories {
    margin-top: 3em;
}

.categoriesPanel .catTitles {
    border-bottom: 1px solid #24252b;
    padding-bottom: 0.8em;
    margin-bottom: 1.2em;
}

.categoriesPanel .catSelections {
    padding-left: 0.6em;
}

.categoriesPanel .selectAllWrap {
    padding: 0em;
}

.categoriesPanel .catActionBtnsWrap {
    width: 100vw;
    height: 4em;
    background-color: #24252b;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2em;
    margin-left: -15.5vw;
}

.categoriesPanel .catActionBtns {
    width: 50%;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0,0,0,0);
    border-radius: 0em;
    border: none;
}

.categoriesPanel .actionArrows {
    height: 1.5em;
    margin-left: 0.5em;
}

.categoriesPanel .catSelectionBtn {
    border-right: 1px solid white;
    justify-content: flex-end;
    padding-right: 3em;
}

.categoriesPanel .catSeeAllBtn {
    padding-left: 3em;
}











/* PROFILE */
#profile .pageTitle {
    margin-bottom: 2.5em;
}

#profile .infoDivider,
#profile .controlGroup {
    width: 40%;
    /* margin-bottom: 2.7em; */
}

#profile .infoDivider {
    border-bottom: 1px solid #9b9b9b;
}

#profile .infoDivider.name,
#profile .infoDivider.city,
#profile .controlGroup.city {
    margin-bottom: 2.5em;
}

#profile .infoDivider.email,
#profile .infoDivider.organization {
    margin-bottom: 2.5em;
}

#profile .controlGroup {
    position: relative;
    padding-top: 1.56em;
}

#profile .absolutePlaceholders {
    font-size: 0.8em;
    font-weight: 300;
    letter-spacing: 0.4px;
    position: absolute;
    /* top: auto; */
    bottom: 0.17em;
    left: 0;
    transition: all ease 0.5s;
}

#profile .absolutePlaceholders.focus {
    font-size: 0.7em;
    /* top: 0; */
    bottom: 2.56em;
}

#profile .updateInput {
    height: auto;
    font-size: 1em;
    color: rgb(211, 33, 161);
    border: none;
    border-bottom: 1px solid #9b9b9b;
    border-radius: 0em;
    padding: 0em;
}

#profile .deleteNote {
    width: 47%;
    /* position: absolute; */
    /* bottom: 0em; */
    line-height: 1.62;
    letter-spacing: 0.4px;
    margin-top: 3.75em;
}

.profileModalLinks:hover {
    /* font-weight: 400; */
    text-decoration: underline;
}

#profile input:-internal-autofill-previewed, 
#profile input:-internal-autofill-selected {
    background-color: rgba(0,0,0,0) !important;
    color: rgb(211, 33, 161) !important;
}


/* DELETE AND UNSUBSCRIBE MODALS */
.profileFeedbackModal {
    width: auto;
    height: auto;
    position: absolute;
    top: 3.5em;
    background-color: rgba(0,0,0,0);
    padding-left: 0em;
    padding-right: 0em;
    padding-bottom: 0em;
}

.profileFeedback {
    z-index: 10;
    margin: 0% auto;
}

.profileFeedback .feedInner {
    width: 16.8em;
    position: relative;
    margin: 0% auto;
}

.profileFeedback .bubbleDiv {
    width: 12.3em;
    height: 3.8em;
    background-color: #b61c8b;
    cursor: pointer;
    margin: 0% auto;
    padding: 0em;
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 7;
    position: absolute;
    top: -3rem;
    left: 0;
    right: 0;
    margin: 0% auto;
}

.profileFeedback .bubbleText {
    font-size: 0.69em;
    line-height: 1.31;
    letter-spacing: 0.6px;
}

.profileFeedback .insightsFeedbackDiv {
    width: 16.8em;
    height: 21.7em;
    background-color: white;
    padding: 3.5em 1.5em 0em;
    border-radius: 0.4rem;
    box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, 0.07);
    margin: 0% auto;
}

.profileFeedback .form {
    height: 100%;
}

.profileFeedback .form .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profileFeedback .insightsFeedbackDiv .emailGroup {
    margin-bottom: 0.7em;
}

.profileFeedback .insightsFeedbackDiv .email {
    height: auto;
    font-size: 0.69em;
    letter-spacing: 0.4px;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #24252b;
    line-height: 1.5;
    padding: 0em;
    border-radius: 0em;
    margin-bottom: 0em;
}

.profileFeedback .insightsFeedbackDiv .email::placeholder {
    color: #24252b;
}

.profileFeedback .textAreaGroup {
    height: 100%;
    margin-bottom: 0em;
}

.profileFeedback .textArea {
    width: 100%;
    height: auto !important;
    min-height: 22.5em;
    font-size: 0.6em;
    color: #24252b;
    letter-spacing: 0.6px;
    overflow: scroll;
    padding: 0em;
    border: 0em;
    border-radius: 0em;
    box-shadow: none;
    resize: none;
    line-height: 1.69;
    letter-spacing: 0.6px;
}

.profileFeedback .textArea::placeholder {
    color: #24252b;
    opacity: 0.6;
}

.profileFeedback .sendDiv {
    height: 3.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #bec3cb;
}

.profileFeedback .sendDiv .text {
    font-size: 0.7em;
}

.profileFeedback .sendDiv .iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileFeedback .sendDiv .sendIcon {
    height: 0.8em;
    cursor: pointer;
}

.profileFeedback .sentMessage {
    padding-top: 4em;
}










/* Submit An Indicator ******/
.submitAnIndicator {
    background-color: #f7f7f7;
}

.submitAnIndicator .innerPage {
    min-height: 100vh;
    margin-bottom: 10em;
}

.submitAnIndicator .title {
    font-size: 1.6em;
    line-height: 1.24;
    letter-spacing: 1px;
    margin-bottom: 2.2em;
}

.submitAnIndicator .requiredText {
    font-size: 1.4em;
    margin-bottom: 1.1em;
}

/* .indicatorForm {
    margin-bottom: 50em !important;
} */

.indicatorForm form {
    z-index: 4;
    position: relative;
}

.indicatorForm .control-label {
    font-size: 1.25em;
    margin-bottom: 0em;
    color: rgb(211, 33, 161);
}

.indicatorForm .opacityText {
    opacity: 0.5;
}

    /* textArea */
.indicatorForm .textAreaGroup {
    position: relative;
    margin-bottom: 0em;
}

.indicatorForm .textAreaGroup.active {
    opacity: 1;
}

.indicatorForm .textAreaGroup.inActive {
    opacity: 0.5;
}

.indicatorForm .textAreaTitle {
    height: 1.2em;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1em;
    border-right: 0.05em solid #b61c8b;
    padding: 0.1em 0.3em 0em 0em;
    transition: border-right ease-in-out .20s;
}

.indicatorForm .textAreaTitle.full {
    border-right: none;
}

.indicatorForm .textArea {
    width: 100%;
    height: 100% ;
    font-size: 1em;
    cursor: pointer;
    color: #9b9b9b;
    /* padding: 0.75em; */
    background-color: rgba(0,0,0,0);
    text-indent: 20.5em;
    margin: 0;
    padding: 0.1em 0.15em 0.15em 0em;
    overflow: hidden;
    border: none;
    border-radius: 0em;
    box-shadow: none;
    /* transition: border-color ease-in-out .15s,
    box-shadow ease-in-out .15s; */
}

.indicatorForm .textArea:focus {
    opacity: 1;
    cursor: text;
}

.indicatorForm .textArea.deny {
    opacity: 1;
}

.indicatorForm .taUnderLineOne {
    position: absolute;
    top: 1.9em;
    left: 20.45em;
    border: 1px solid #b61c8b;
}

.indicatorForm .detailFormGroup .taUnderLineOne {
    width: 56rem;
}

.indicatorForm .summaryFormGroup .taUnderLineOne {
    width: 53rem;
    left: 22.3em;
}

.indicatorForm .taUnderLineTwo {
    width: 100%;
    height: 0.1em;
    border-bottom: 1px solid #b61c8b;
}

.indicatorForm .summaryFormGroup .textArea {
    text-indent: 22.5em;
}

.indicatorForm .summaryFormGroup .textArea::placeholder {
    color: rgb(211, 33, 161);
}

    /* Question Buttons */
.indicatorForm .qWrappers {
    position: relative;
    margin-top: 1.8em;
}

.indicatorForm .qBtns {
    background-color: rgba(0,0,0,0);
    padding-top: 0em;
    padding-bottom: 0em;
    padding-left: 0em;
    border: none;
}

.indicatorForm a.anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
}

.indicatorForm .qBtns.access,
.indicatorForm .textArea.access {
    opacity: 1;
    cursor: pointer;
}

.indicatorForm .qBtns.deny {
    opacity: 0.5;
    cursor: default;
}

.indicatorForm .qBtns.access:hover .opacityText,
.indicatorForm .textArea.deny:hover {
    opacity: 1;
}

.indicatorForm .qBtns:hover {
    background-color: rgba(0,0,0,0);
}

.indicatorForm .qBtnTitle {
    font-size: 1.25em;
}

.indicatorForm .carrot {
    width: 0.86em;
    margin-left: 0.5em;
}

.indicatorForm .openDiv {
    width: 103%;
    height: auto;
    overflow: scroll;
    z-index: 6;
    position: absolute;
    top: -0.5em;
    left: -2em;
    border-radius: 0.4em;
    background-color: white;
    padding: 1.8em 0em 0em;
}

.indicatorForm .openDiv .paddingLeft {
    padding-left: 1.9em;
}

.indicatorForm .openDiv .marginLeft {
    margin-left: 1.9em;
}

.indicatorForm .openDiv .headers {
    font-size: 1.25em;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-bottom: 1em;
}

/* .indicatorForm .inTypesOpenDiv {
    height: 14.5em;
} */

/* .indicatorForm .sourceOpenDiv {
    height: 15.5em; 
} */

/* .indicatorForm .trafficking.openDiv {
    height: 14.5em;
} */

.indicatorForm .selectAllWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0em 2.3em 0em 2em;
}

.iFormSwitch .react-switch-bg {
    width: 3em !important;
    height: 0.95em !important;
}

.categoriesPanel .iFormSwitch .react-switch-bg {
    width: 2.3em !important;
    height: 0.75em !important;
}

.iFormSwitch .react-switch-handle {
    width: 1.4em !important;
    height: 1.4em !important;
    top: -0.3rem !important;
    box-shadow: none !important;
}

.categoriesPanel .iFormSwitch .react-switch-handle {
    width: 1em !important;
    height: 1em !important;
    top: -0.2rem !important;
}

.iFormSwitch svg {
    display: none !important;
}

.iFormSwitch.allFalse .react-switch-bg {
    background-color: #9b9b9b !important;
    opacity: 0.45 !important;
}

.iFormSwitch.allFalse .react-switch-handle {
    background-color: #9b9b9b !important;
    transform: translateX(0px) !important;
}

.iFormSwitch.allTrue .react-switch-bg {
    background-color: #b61c8b !important;
    opacity: 0.4 !important;
}

.iFormSwitch.allTrue .react-switch-handle {
    transform: translateX(1.7em) !important;
    background-color: #b61c8b !important;
}

.categoriesPanel .iFormSwitch.allTrue .react-switch-handle {
    transform: translateX(1.31em) !important;
}

.indicatorForm .options {
    height: 2.4em;
    cursor: pointer;
    padding-left: 2.2em;
}

.indicatorForm .options:hover {
    background-color: rgb(211, 33, 161);
    color: white;
}

/* .indicatorForm .options.checkbox,
#dashboard .options.checkbox,
.iCards .options.checkbox,
.signInAndAgree .options.checkbox,
.categoriesPanel .options.checkbox {
    margin: 0em;
} */

.indicatorForm .options.checkbox label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/* Hide the browser's default checkbox */
.options input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.options .checkmark {
    width: 1.13em;
    height: 1.13em;
    position: absolute;
    top: 0;
    /* bottom: 0; */
    left: 0;
    /* margin-top: auto; */
    /* margin-bottom: auto; */
    background-color: white;
    border: 1px solid #24252b;
    border-radius: 1rem;
}

#dashboard .options.dd .checkmark {
    top: 0.09em;
}

.indicatorForm .options .checkmark {
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

/* When the checkbox is checked, add a magenta background */
.options input:checked ~ .checkmark {
    background-color: rgb(211, 33, 161);
    border-color: rgb(211, 33, 161);
}

  /* Create the checkmark/indicator (hidden when not checked) */
.options .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.options input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.options .checkmark:after {
    width: 100%;
    height: 100%;
    background: url('./Styles/Images/whiteCheckmark.png');
    background-size: 0.5em;
    background-repeat: no-repeat;
    background-position: center;
}


/****** *****/

.indicatorForm .otherText {
    width: 80%;
    margin-left: 2.2em;
    color: #24252b;
    border: none;
    border-radius: 0em;
    border-bottom: 1px solid #24252b;
    box-shadow: none;
    padding: 0em;
}

.indicatorForm .otherText::placeholder {
    color: #24252b;
}

.sourceWrapper .backendText {
    display: inline;
}

.indicatorForm .applyBtns,
.indicatorForm .applyBtns:active,
.indicatorPolicy .applyBtns,
.indicatorPolicy .applyBtns:active {
    background-color: rgb(211, 33, 161);
    color: white;
    padding: 0.9em 2.3em;
    border-radius: 0.4rem;
    box-shadow: -0.1em -0.1em 0.2em 0 rgba(96, 119, 159, 0.24);
    border: none;
}

.indicatorForm .applyBtns:active,
.indicatorPolicy .applyBtns:active {
    background-color: rgb(179, 26, 135);
}

.indicatorForm .applyBtns.deny {
    opacity: 0.45;
    background-color: rgb(211, 33, 161);
    color: white;
}

.indicatorForm .applyBtns.access {
    opacity: 1;
}

.indicatorForm .clearAllBtns,
.indicatorForm .clearAllBtns:hover,
.indicatorForm .clearAllBtns:active {
    background-color: rgba(0,0,0,0);
    color: #bec3cb;
    line-height: 1.65;
    font-weight: 300;
    border: none;
}

.indicatorForm .clearAllBtns:hover,
.indicatorForm .clearAllBtns:active {
    color: rgb(211, 33, 161);
}

.indicatorForm .backendDisplay {
    opacity: 0.5;
}

.indicatorForm .backendText {
    font-size: 0.8em;
    margin-bottom: 0em;
}


.indicatorForm .moreInfo {
    margin-top: 3.4em;
}

.indicatorForm .uploadDocsWrapper {
    width: 70%;
    margin: 1.55em 0em 3em;
}

.indicatorForm .uploadDocsTitle,
.indicatorForm .moreInfoTitle {
    font-size: 1.4em;
    margin-bottom: 0em;
}

.indicatorForm .uploadDocsTitle {
    margin-bottom: 0.4em;
}

.indicatorForm .docsBtns {
    position: relative;
    color: rgb(211, 33, 161);
    background-color: rgba(0,0,0,0);
    border: none;
    border-radius: 0em;
}

.indicatorForm .uploadFirstDocBtn {
    width: 85%;
    font-size: 1.4em;
    text-align: left;
    padding: 0em;
}

.indicatorForm .uploadFirstDocBtn:hover {
    text-decoration: underline;
}

.indicatorForm .addDocsBtnWrapper {
    width: 100%;
    padding: 1em 0.2em;
    border-top: 1px solid #9b9b9b;
    border-bottom: 1px solid #9b9b9b;
}

.indicatorForm .addDocsBtn {
    font-size: 1.2em;
    padding: 0em;
}

.indicatorForm .addDocsBtn:hover {
    transform: scale(1.02, 1.02);
}

.indicatorForm .addDocsIcon {
    width: 1.3em;
    height: 1.3em;
    margin-right: 1em;
}

.indicatorForm .hiddenChooseFile {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.indicatorForm .hiddenChooseFile::-webkit-file-upload-button {
    display: none !important;
}

.indicatorForm .docsBackendDisplay {
    width: 100%;
}

.indicatorForm .fileTableRows,
.iCardModal .fileTableRows {
    width: 100%;
    padding: 1em 0.5em;
    border-top: 1px solid #9b9b9b;
}

.iCardModal .fileTableRows {
    border-top: none;
    border-bottom: 1px solid #9b9b9b;
}

.indicatorForm .fileIcon,
.iCardModal .fileIcon {
    height: 1.4em;
    margin-right: 1.4em;
}

.indicatorForm .fileName,
.iCardModal .fileName {
    flex: 3;
    font-size: 1.3em;
    letter-spacing: 0.6px;
    line-height: normal;
    word-break: break-all;
}

.iCardModal .fileName {
    font-size: 1em;
}

.indicatorForm .removeFileIconDiv,
.iCardModal .downloadFileIconDiv  {
    margin-left: 1.4em;
}

.indicatorForm .removeFileIcon {
    width: 1.3em;
    height: 1.3em;
}

.iCardModal .fileDownloadIcon {
    width: 1.1em;
    height: 1.1em;
}




.indicatorForm .moreInfo {
    position: relative;
}

.indicatorForm .moreInfoDisabled {
    width: 75vw;
    height: 100%;
    background-color: rgba(247,247,247, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}


.indicatorForm .briefDescription .form-group {
    width: 100%;
    margin-bottom: 0em;
}

.indicatorForm .inputSkip {
    width: 3em;
    display: inline-block;
    /* z-index: 4; */
    opacity: 0.7;
    background-color: rgba(0,0,0,0);
    border: none;
    color:  rgb(211, 33, 161);
    padding: 0em;
    cursor: pointer;
}

.indicatorForm .inputSkip:hover {
    opacity: 1;
}

.indicatorForm .briefDescription .inputSkip {
    margin-right: -3em;
}

.indicatorForm .skipMoreInfo,
.indicatorForm .skipMoreInfo:active {
    width: 100vw;
    height: 4em;
    font-size: 1em;
    color: white;
    padding: 0.9em 2.3em;
    text-align: left;
    background-color: #24252b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8em;
    margin-left: -15.5vw;
    border-radius: 0em;
    border: none;
    z-index: 20;
}

.indicatorForm .skipArrow {
    height: 1.5em;
    margin-left: 0.6em;
}






/* Accept Policy to submit Indicator  */
.indicatorPolicy .backArrowBtn {
    margin-bottom: 3.5em;
    padding: 0em;
    background-color: rgba(0,0,0,0);
    border: none;
}

.indicatorPolicy .backImg {
    height: 1.6em;
    margin-right: 1.1em;
}

.indicatorPolicy .backArrowBtn p {
    font-size: 1.6em;
    margin: 0em;
}

.indicatorPolicy .privacyTitle {
    font-size: 1.4em;
}

.indicatorPolicy .readMore {
    margin-top: 1em;
    padding: 0em;
    border: none;
    background-color: rgba(0,0,0,0);
}

.indicatorPolicy .applyBtns {
    margin-top: 3.7em;
}




/* Indicator was Submitted page */
/* .indicatorSubmitted .innerDiv {
    width: 120%;
} */
.indicatorSubmitted .submittedTitle {
    font-size: 1.6em;
    margin: 0em 0em 1em;
}

.indicatorSubmitted .addAnother:hover,
.indicatorSubmitted .goToDash:hover {
    color: rgb(211, 33, 161);
    cursor: pointer;
}

.indicatorSubmitted .addArrow,
.indicatorSubmitted .backArrow {
    width: 1.6em;
    height: 1.2em;
    margin-left: 0.5em;
}

.indicatorSubmitted .goToDash {
    margin-top: 2em;
}

.indicatorSubmitted .backArrow {
    background: url('./Styles/Images/darkRightArrow.png');
    background-size: auto 100% !important;
    background-repeat: no-repeat !important;
}

.indicatorSubmitted .goToDash:hover .backArrow {
    background: url('./Styles/Images/pinkRightArrow.png');
}

.indicatorSubmitted .smallSortRow {
    margin-top: 6em;
}






/* Privacy Policy Modal && Terms of Service Modal */
.innerModal.policyModal .policyInner,
.innerModal.termsModal .termsInner {
    height: 100%;
    overflow: scroll;
}

.innerModal.policyModal .closeWrapper,
.innerModal.termsModal .closeWrapper  {
    text-align: right;
}

.innerModal.policyModal .closeIcon,
.innerModal.termsModal .closeIcon {
    width: 1em;
    cursor: pointer;
}

.innerModal.policyModal .bodyText,
.innerModal.termsModal .bodyText {
    line-height: 1.62;
    letter-spacing: 0.4px;
}

.innerModal.termsModal .signInfoWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.innerModal.termsModal .signInfo {
    width: 40%;
}







/* Footer */
.footer {
    height: 20em;
    position: relative;
    background-color: #24252b;
    color: white;
    padding: 5em 0em 1em;
    z-index: 15;
}

.footer .container-fluid {
    height: 100%;
}

.footer .row {
    height: 100%;
    width: 69vw; /* Same as .footerFeedBack .inner */
    margin: 0% auto;
}

.footer .col.one {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0em 1.1em 0em 0em;
}

.footer .exploreBtn {
    cursor: pointer;
}

.footer .exploreText {
    font-size: 1.31em;
    line-height: 1.73;
    letter-spacing: 0.6px;
}

.footer .exploreArrow {
    width: 2.5em;
}


.footer .contactDiv, 
.footer .copyRight,
.footer .contactInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.footer .copyRight {
    width: 60%;
}

.footer .copyRight p,
.footer .copyRight .links {
    font-size: 0.5em;
}

.footer .copyRight .links {
    cursor: pointer;
}

.footer .copyRight .links:hover {
    color: rgb(211, 33, 161);
}


.footer .col.two {
    padding: 0em 0em 0em 1.1em;
}



.footerFeedback {
    width: 20vw; /* Same as .footer .row */
    z-index: 10;
    position: absolute;
    top: -3rem;
    left: 0;
    right: 0;
    margin: 0% auto;
    margin-right: 15.5vw;
}

.footerFeedback.open {
    top: 0;
}

.footerFeedback .feedInner {
    width: 16.8em; 
    float: right;
    margin-right: -2em;
}

.footerFeedback .feedInner.open {
    position: absolute;
    right: 0;
    top: -10rem;
}

.footerFeedback .bubbleDiv {
    width: 12.3em;
    height: 3.8em;
    background-color: #b61c8b;
    cursor: pointer;
    margin: 0% auto;
    padding: 0em;
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 7;
}

.footerFeedback .bubbleDiv p {
    margin: 0em;
}

.footerFeedback .bubbleDiv.open {
    position: absolute;
    top: -3rem;
    left: 0;
    right: 0;
    margin: 0% auto;
}

.footerFeedback .bubbleText {
    font-size: 0.69em;
    /* letter-spacing: 1.5px; */
    line-height: 1.31;
    letter-spacing: 0.6px;
}

.footerFeedback .insightsFeedbackDiv {
    width: 16.8em;
    height: 21.7em;
    background-color: white;
    padding: 3.5em 1.5em 0em;
    border-radius: 0.4rem;
    box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, 0.07);
    margin: 0% auto;
}

.footerFeedback .form {
    height: 100%;
}

.footerFeedback .form .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footerFeedback .insightsFeedbackDiv .emailGroup {
    margin-bottom: 0.7em;
}

.footerFeedback .insightsFeedbackDiv .email {
    height: auto;
    font-size: 0.69em;
    letter-spacing: 0.4px;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #24252b;
    line-height: 1.5;
    padding: 0em;
    border-radius: 0em;
    margin-bottom: 0em;
}

.footerFeedback .insightsFeedbackDiv .email::placeholder {
    color: #24252b;
}

.footerFeedback .textAreaGroup {
    height: 100%;
    margin-bottom: 0em;
}

.footerFeedback .textArea {
    width: 100%;
    height: auto !important;
    min-height: 22.5em;
    font-size: 0.6em;
    color: #24252b;
    letter-spacing: 0.6px;
    overflow: scroll;
    padding: 0em;
    border: 0em;
    border-radius: 0em;
    box-shadow: none;
    resize: none;
    line-height: 1.69;
    letter-spacing: 0.6px;
}

.footerFeedback .textArea::placeholder {
    color: #24252b;
    opacity: 0.6;
}

.footerFeedback .sendDiv {
    height: 3.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #bec3cb;
}

.footerFeedback .sendDiv .text {
    font-size: 0.7em;
}

.footerFeedback .sendDiv .iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footerFeedback .sendDiv .fileIcon {
    height: 1em;
}

.footerFeedback .sendDiv .attachmentIcon {
    width: 0.8em;
    margin: 0em 1em;
    cursor: pointer;
}

.footerFeedback .sendDiv .sendIcon {
    height: 0.8em;
    cursor: pointer;
}

.footerFeedback .sentMessage {
    padding-top: 4em;
}



.footer .spacer {
    width: 13em; /* Same as .footerFeedback .inner */
}


.footer .divider {
    width: 100%;
    height: 1px;
    border-top: 0.1em solid #bec3cb;
    margin: 9.7rem 0em 3.3em;
}

.footer .contactDiv {
    width: 100%;
}

.footer .contactDiv .links {
    font-size: 0.67em;
    line-height: 2.14;
    letter-spacing: 1px;
    font-weight: 600;
    cursor: pointer;
}

.footer .contactDiv .links:hover {
    color: rgb(211, 33, 161);
}

.footer .contactDiv .footerInlineLogo {
    height: 2em;
    margin-left: 0.2em;
}








/* IPAD PRO     ***************************/
@media screen and (max-width: 1024px){

    .ipadBreak {
        display: block !important;
    }


/* MODALS ***/
    .ReactModal__Content {
        width: 75vw !important;
    }







/* INNER DIVS *******************/





/* LEFT SIDE BAR *******/
    .leftSideBar {
        width: 5em !important;
        top: 6em !important;
    }



/* RIGHT SIDE BAR *******/
    .rightSideBar {
        height: auto !important;
    }







/* HOME PAGE */
    .homePage .sections {
        min-height: 50vh !important;
    }

    .homePage .actionRow.two {
        height: auto !important; 
    }

    .homePage .actionCol {
        padding: 0em 2em !important;
    }

    .ResetPassword .passwordResetBtn {
        height: 4em !important;
    }

    .homePage #about {
        height: auto !important;
    }

    .homePage #about.sections {
        min-height: 55vh !important;
    }

    .carouselItem {
        height: 55vh !important;
    }

    .carousel-1 {
        background-position: 90% 100% !important;
    }

    .carousel-2 {
        background-position: 50% 100% !important;
    }

    .homePage .partners {
        width: 40vw !important;
    }






    /* Reset Password */
    #resetPasswordWrapper .innerPage {
        display: block !important;
        padding-top: 10em !important;
    }

    .ResetPassword .form {
        width: 80% !important;
    }

    .ResetPassword .passwordResetBtn {
        min-width: 12em !important;
        width: 12em !important;
        height: 3em !important;
    }









    /* iCards Modal */
    .iCardModal .iCard-innerModal {
        width: 65% !important; /* same as .downloadBtn */
        /* height: auto !important; */
        /* max-height: 85% !important; */
    }

    .iCardModal .downloadBtn {
        width: 65% !important; /* same as iCard-innerModal */
        top: 88vh !important;
    }






    /* Dashboard/Workbench */ 
    /* #dashboard .innerPage.extraSpace {
        margin-bottom: 10em !important;
    } */

    #dashboard .dropdown {
        width: 47% !important;
    } 





    /* SEE MY SELECTION */
    #seeMySelections .pillsContainer {
        width: 90% !important;
    }




    /* CATEGORIES PANEL */
    .categoriesPanel .closePanelIcon {
        width: 1.5em !important;
        height: 1.5em !important;
        margin-top: 0.5em !important;
    }

    .categoriesPanel .catRow {
        height: 99em !important;
        flex-direction: column !important;
        flex-wrap: wrap !important;
        justify-content: flex-start !important;
    }

    .categoriesPanel .catColumns {
        width: 48% !important;
    }

    .categoriesPanel .catColumns.one,
    .categoriesPanel .catColumns.two {
        align-self: flex-start !important;
    }

    .categoriesPanel .catColumns.three {
        align-self: flex-end !important;
    }








    /* PROFILE */
    #profile .infoDivider,
    #profile .deleteNote,
    #profile .controlGroup {
        width: 60% !important;
    }




 
    




/* Submit an Indicator Page ****************/
    /* .indicatorForm {
        margin-bottom: 40em !important;
    } */

    .indicatorForm .detailFormGroup .taUnderLineOne {
        width: 12.5em !important;
        left: 19.6em !important;
    }

    .indicatorForm .openDiv {
        height: auto !important;
    }

    .indicatorForm .uploadFirstDocBtn {
        width: 95% !important;
    }

    .indicatorForm .summaryFormGroup .textArea {
        text-indent: 22em !important;
    }

    .indicatorForm .summaryFormGroup .taUnderLineOne {
        width: 10.7em !important;
        left: 21.3em !important;
    }

    .indicatorForm .submitBtn,
    .indicatorForm .submitBtn:active {
        margin-left: -15.5vw !important;
    }








    /* FOOTER *******************/
    .footer .row {
        width: 70vw !important;
    }

    .footer .copyRight {
        width: 75% !important;
    }

    .footer .contactDiv {
        display: block !important;
    }

    .footer .contactDiv .footerInlineLogo {
        height: 1.5em !important;
        margin-left: 0.3em !important;
    }
}








/* IPAD PRO LANDSCAPE */
@media screen and (max-width: 1024px) and (max-height: 768px) {
    .homePage #about.sections {
        min-height: 75vh !important;
    }

    .carouselItem {
        height: 75vh !important;
    }
}









/* IPAD *********/
@media screen and (max-width: 768px){
    body { cursor: pointer !important}


    /* LEFT SIDE BAR */
    .leftSideBar {
        z-index: 0 !important;
    }

    .homeSidebar .anchors.white:hover .titles,
    .homeSidebar .anchors.blue:hover .titles {
        color: rgba(0,0,0,0) !important;
    }

    .homeSidebar .anchors.white.active .titles {
        color: #ffffff !important;
    }

    .homeSidebar .anchors.blue.active .titles {
        color: #24252b !important;
    } 
    



    /* INNERS ******/
    .innerPage {
        z-index: 12 !important;
        margin-bottom: 10em !important;
    }
    
    .submitAnIndicator .inner {
        width: 100% !important;
        padding: 4em 7.5em 4em !important;
        margin-left: 0em !important;
    }






    /* HOME PAGE */
    .homePage .actionCol {
        padding: 0em 1em !important;
    }

    .homePage .actionCol.one {
        padding-left: 4em !important;
    }

    .toolTips.forRequestAccess {
        width: 15em !important;
    }

    .homePage #about.sections {
        min-height: 65vh !important;
        height: 100% !important;
    }

    .carouselItem {
        height: 65vh !important;
    }

    .carousel-1 {
        background-position: 70% 100% !important;
    }

    .homePage .partners {
        padding: 2em 4em 1em 2em !important;
    }

    .resourcesCard {
        margin-bottom: 1em !important;
    }






    /* iCards */
    .toolTips.foriCards .tooltip-inner {
        padding: 1em 0.7em 0.5em 0.7em !important;
    }








    
    /* Dashboard/Workbench */
    /* #dashboard .innerPage.extraSpace {
        margin-bottom: 25em !important;
    }        */







    /* Terms Modal */
    .innerModal.termsModal .signInfoWrapper {
        flex-direction: column !important;
    }
    
    .innerModal.termsModal .signInfo {
        width: 70% !important;
    }

    .innerModal.termsModal .signInfo.one {
        margin-bottom: 6em !important;
    }
    






    /* iCARD MODAL */
    .iCardModal .iCard-innerModal {
        width: 90% !important;
    }    

    .iCardModal .downloadBtn {
        width: 90% !important; /* same as iCard-innerModal */
        top: 87vh !important;
    }
    





    /* PROFILE */
    #profile .infoDivider,
    #profile .deleteNote,
    #profile .controlGroup {
        width: 70% !important;
    }

   




    
    /* SUBMIT AN INDICATOR *****/
    .indicatorForm .textArea {
        text-indent: 19.6em !important; 
    }

    .indicatorForm .detailFormGroup .taUnderLineOne {
        width: 13.5em !important;
    }

    .indicatorForm .uploadDocsWrapper {
        width: 100% !important;
    }

    .indicatorForm .summaryFormGroup .taUnderLineOne {
        width: 12em !important;
    }

    .indicatorForm .openDiv {
        left: -1em !important;
    }

    .indicatorForm .skipMoreInfo, 
    .indicatorForm .skipMoreInfo:active {
        margin-left: -15.6vw !important;
    }



    /* .indicatorSubmitted .innerDiv {
        width: 90% !important;
    } */









    /* CATEGORIES PANEL */
    .categoriesPanel .catRow {
        height: 105em !important;
    }

    .categoriesPanel .catColumns {
        width: 48% !important;
    }








    /* FOOTER *********/
    .footerFeedback {
        width: 27vw !important;
    }

    .footer .row {
        width: 88vw !important;
    }




}






/* iPhone 6/7/8/ Plus landscape */ 
@media screen and (max-width: 736px) {






    /* CATEGORIES PANEL */
    .categoriesPanel .catRow {
        height: 100% !important;
    }

    .categoriesPanel .catColumns {
        width: 100% !important;
    }







    
    /* FOOTER */
    .footer {
        height: 100% !important;
    }

    /* .footer.authUser {
        margin-bottom: 3.6em !important;
    } */

    .homePage .footer.authUser {
        margin-bottom: 0em !important;
    }

    .footer .col {
        padding: 0em !important;
    }

    .footer .exploreBtn {
        margin-bottom: 2.8em !important;
    }

    .footer .mobile .contactDiv {
        display: block !important;
    }

    .footer .mobile .contactDiv .middleLink{
        margin: 2em 0em !important;
    }

    .footer .mobile .copyRight {
        max-width: 50% !important;
        margin-top: 2.8em !important;
    }


    .footerFeedback {
        width: 60% !important;
        margin-right: auto !important;
    }

    .footerFeedback .feedInner {
        float: none !important;
        margin: 0% auto !important;
    }
}







/* iPHONE 6/7/8 PLUS ********/
@media screen and (max-width: 620px) and (orientation: portrait) { /* 414px */
    body {
        font-size: 14px !important;
    }


    .ReactModal__Content {
        width: 80vw !important;
        height: 80vh !important;
        padding: 2em !important;
    }


    .homePage .actionRow.one,
    .col-sm-0,
    .homePage .actionCol.one {
        display: none !important;
    }

    .homePage .actionRow.three {
        display: block !important;
    }

    .pageTitle {
        font-size: 1.2em !important;
        margin-bottom: 2em !important;
    }


    /* LEFT SIDE BAR *****/
    .leftSideBar {
        width: 100vw !important;
        display: flex !important;
        flex-direction: row-reverse !important;
        align-items: flex-start !important;
        justify-content: space-between !important;
        position: absolute !important;
        top: 1.5em !important;
    }

    .leftSideBar .barsLink {
        margin-top: 0.5em !important;
    }

    .homeSidebar .logoWrapper,
    .leftSideBar .logoWrapper {
        font-size: 1em !important;
        margin-bottom: 0em !important;
    }

    .leftSideBar ul {
        width: 3em !important;
        margin-bottom: 0em !important;
    }

    .leftSideBar .wrappers {
        height: 1.1em !important;
    }

    .leftSideBar .colors {
        width: 0.9em !important;
        margin-left: 0em !important;
    }




    /* Right side bar */
    .rightSideBar {
        width: 100vw !important;
        height: 3.6em !important;
        z-index: 3000 !important;
        flex-direction: row !important;
        justify-content: space-evenly !important;
        bottom: 0 !important;
        top: unset !important;
        background-color: #ffffff !important;
        padding: 0em 2.1em !important;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .rightSideBar .buttonHolders {
        width: 3.6em !important;
        height: 3.6em !important;
        border: none !important;
    }



    .toolTips.forRightSideBar {
        /* left: 1em !important; */
        bottom: 3em !important;
        top: unset !important;
    }

    #profileTooltip,
    #notificationsTooltip,
    #addIndicatorTooltip,
    #bookmarksTooltip,
    #settingsTooltip {
        top: inherit !important;
    }

    #profileTooltip {
        left: 2rem !important;
    }
    
    #notificationsTooltip {
        /* left: 8.4em !important; */
    }

    #notificationsTooltip.zeroNew {
        margin-top: 0rem !important;
    }

    #notificationsTooltip.forRightSideBar.tooltip.left.zeroNew .tooltip-arrow {
        margin-top: 0rem !important;
    }

    #addIndicatorTooltip {
        left: 15.1em !important;
    }

    #bookmarksTooltip {
        right: 1em !important;
        left: unset !important;
    }

    .toolTips .tooltip-inner {
        max-width: 15em !important;
    }

    .tooltip.top .tooltip-arrow {
        border-width: 10px 10px 0 !important;
        left: 50% !important;
        margin-left: -0.9em !important;
        border-left-color: rgba(0,0,0,0) !important;
        border-top-color: white !important;
    }

    .tooltip-arrow:before {
        bottom: 0.15em !important;
        left: -0.37em !important;
    }

    #profileTooltip .tooltip-arrow {
        left: 4.9em !important;
    }

    #bookmarksTooltip .tooltip-arrow {
        left: unset !important;
        right: 1.5rem !important
    } 




    /* INNER PAGES ******/
    .innerPage {
        width: 85vw !important;
        min-height: 100vh !important;
        padding-top: 2.4em !important;
    }

    .submitAnIndicator .inner {
        width: 100% !important;
        padding: 2.4em 2em !important;
        margin-left: 0em !important;
    }









    /* HOME SIDE BAR */
    .homeSidebar .inner {
        width: 100vw !important;
        position: absolute !important;
        top: 1.5em !important;
    }

    .homeSidebar .inner .col-sm-3 {
        padding: 0em !important;
    }
    .homeSidebar .inner .logo {
        float: right !important;
    }

    .homeSidebar .tubes {
        position: fixed !important;
    }

    .homeSidebar .tubes.about {
        top: 1.5em !important
    }

    .homeSidebar .tubes.dna {
        top: 2.5em !important
    }

    .homeSidebar .tubes.resources {
        top: 3.5em !important
    }

    .homeSidebar .colors {
        margin-left: -1.8em !important;
    }






    /* HOME PAGE */
    .homePage .section1 {
        padding-top: 2.5em !important;
        height: 100% !important;
    }

    .homePage .actionRow.two {
        display: block !important; 
    }

    .homePage .actionCol,
    .homePage .actionBtnWrap .index4 {
        padding: 0em 2.5em !important;
    }

    .homePage .requestTitle, 
    .homePage .loginTitle {
        margin-bottom: 2.4em !important;
    }

    .homePage .industryOpenDiv {
        width: 100% !important;
    }

    .homePage .actionBtnWrap .index4 {
        margin-top: 1.4em !important;
    }

    .homePage .actionBtn {
        float: none !important;
    }

    .toolTips.forRequestAccess {
        width: 83vw !important;
        left: 2.5em !important;
    }

    #accessDisabledHoverTool {
        margin-left: 0em;
    }




    /* HOME PAGE ABOUT */
    .carouselItem {
        height: 100vh !important;
        background-position: top center !important;
    }

    .carousel-2 {
        background-position: 25% 100% !important;
    }

    .carousel-caption {
        display: block !important;
        padding-top: 2.6em !important;
    }

    .homePage .partners {
        width: 57vw !important;
        padding: 2em 3.5em 1em 2em !important;
    }

    .homePage .partners .title {
        font-size: 1em !important;
    }

    .homePage .partners .logoDivs {
        height: 4.5em !important;
    }

    .homePage .partners .logoDivs.platforms {
        min-height: 5.5em !important;
    }


    .homePage .credits {
        bottom: 3em !important;
        padding-left: 0em !important;
    }

    .homePage .credits .flexRow {
        align-items: flex-start !important;
    }

    .homePage .credits .firstTitle {
        width: 70% !important;
        font-size: 1em !important;
        text-align: right !important;
        line-height: 1.55 !important;
        letter-spacing: 0.5px !important;
    }

    .homePage .creditIcon {
        height: 1em !important;
        margin-top: 0.5rem !important;
    }

    .homePage .openCredits {
        width: 80% !important;
    }

    .homePage .openCredits .creditsTitle {
        font-size: 1.2em !important;
    } 

    .homePage .openCredits .body, 
    .homePage .openCredits .website {
        font-size: 1em !important;
    }



    /* HOME PAGE DNA */
    .homePage #dna.sections {
        min-height: 50vh !important;
    }

    .homePage #dna {
        background-size: 85% auto !important;
        background-repeat: no-repeat;
    }



    /* HOME PAGE RESOURCES */
    .homePage #resources .row {
        width: 100vw !important;
        padding: 0em 2.5em !important;
    }

    .resourcesWrappers {
        width: 100% !important;
    }

    .homePage .pageNumberWrapper {
        margin-bottom: 5em !important;
    }





    /* Reset password */
    .ResetPassword .form {
        width: 100% !important;
    }


    



    /* Sign In and Agree Page */
    .signInAndAgree .mainText {
        width: 80% !important;
    }

    .signInAndAgree .form {
        width: 100% !important;
    }

    .signInAndAgree .innerPage {
        min-height: 80vh !important;
    }







    /* Supporting Orgs */
    .supportingOrgs .innerPage {
        width: 80vw !important;
    }

    .supportingOrgs .orgsCard .details {
        padding: 1em 1em 1em 1.2em !important;
    }

    .supportingOrgs .orgsCard .details .text {
        margin-bottom: 1em !important;
    }









    /* iCardsContainer & iCards */
    .iCardsContainer {
        margin: 0em !important;
    }

    .iCardsContainer .iCardWrappers {
        width: 100% !important;
        margin-left: 0em !important;
        margin-right: 0em !important;
        /* padding: 0em !important; */
    }

    .iCards .options .checkmark {
        width: 1.3em !important;
        height: 1.3em !important;
    }

    .tooltip.bottom.foriCards .tooltip-arrow {
        /* border-width: 10px 10px 0 !important; */
        /* left: 50% !important; */
        margin-left: 0.2em !important;
        border-bottom-color: rgba(0,0,0,0) !important;
        border-bottom-color: #f5f5f5 !important;
    }

    .toolTips.foriCards .tooltip-arrow:before {
        left: -0.4em !important;
        bottom: -0.85em !important;
    }


    /* Pagination */
    .pageNumberWrapper {
        margin-left: 0em !important;
        margin-bottom: 10em !important;
    }

    .pagination>li {
        display: inline-block !important;
    }

    .pagination .pageBtns a, 
    .pagination .pageBtns.active span {
        width: 4em !important;
        height: 4em !important;
        margin: 0em 0.7em 1.4em !important;
    }







    /* iCARD MODAL */
    .iCardModal .iCard-innerModal {
        max-height: calc(100vh - 12em) !important;
    }

    .iCardModal .mainInfo {
        padding: 0em 3.4em 6em 2.5em !important;
    }

    .iCardModal .categories {
        width: 45% !important;
    }

    .iCardModal .downloadBtn {
        top: 73vh !important;
    }







    /* Dashboard/Workbench */
    #dashboard .innerPage {
        min-height: 90vh !important;
    }
    /* #dashboard .innerPage.extraSpace {
        margin-bottom: 47em !important;
    } */

    #dashboard .dropdown {
        width: 97% !important;
    }  

    #dashboard .seeAllWrapper {
        padding-right: 0em !important;
    }

    #dashboard .seeSelectionBtn,
    .categoriesPanel .catActionBtnsWrap,
    #profile .saveProfileBtn {
        margin-left: -7.5vw !important;
    }

    .smallSortWrapper {
        justify-content: space-between !important;
    }





    /* See My Selections */
    #seeMySelections .pillsContainer {
        width: 100% !important;
    }







    /* CATEGORIES PANEL */
    .categoriesPanel.open .leftSideBar .colors {
        width: 1.9em !important;
    }

    .categoriesPanel .closePanelIcon {
        margin: 0em 2em !important;
    }

    .categoriesPanel .catSelectionBtn {
        padding-right: 2em !important;
    }

    .categoriesPanel .catSeeAllBtn {
        padding-left: 2em !important;
    }







    /* PROFILE */
    #profile .infoDivider,
    #profile .deleteNote,
    #profile .controlGroup {
        width: 100% !important;
    }

    #profile .deleteNote {
        position: relative !important;
    }






    /* SUBMIT AN INDICATOR PAGE */
    .submitAnIndicator .title,
    .submitAnIndicator .requiredText,
    .indicatorForm .textAreaTitle.full{
        font-size: 1em !important;
    }

    .indicatorForm .textAreaTitle,
    .indicatorForm .qBtns,
    .indicatorForm .openDiv .headers {
        font-size: 1.2em !important;
    }

    .indicatorForm .qBtnTitle {
        font-size: inherit !important;
    }

    .indicatorForm .opacityText {
        font-size: 0.83em !important;
    }
    
    .indicatorForm .textAreaTitle {
        border: none !important;
    }

    .indicatorForm .textArea,
    .indicatorForm .summaryFormGroup .textArea {
        margin-top: 1.5em !important;
        text-indent: 0em !important;
    }

    .indicatorForm .textArea.leftLine,
    .indicatorForm .summaryFormGroup .textArea.leftLine {
        border-left: 0.05em solid #b61c8b !important;
    }

    .indicatorForm .detailFormGroup .taUnderLineOne,
    .indicatorForm .summaryFormGroup .taUnderLineOne {
        width: 100% !important;
        left: 0em !important;
        margin-top: 2em !important;
    }

    .indicatorForm .openDiv .paddingLeft {
        padding-left: 1.65em !important;
    }

    .indicatorForm .uploadFirstDocBtn {
        width: 100% !important;
    }

    .indicatorForm .summaryFormGroup .textArea::placeholder {
        padding-left: 0.3em !important;
    }

    .indicatorForm .summaryFormGroup .taUnderLineTwo {
        margin-top: 0.3em !important;
    }

    .indicatorForm .qBtns {
        text-align: left !important;
    }

    .indicatorForm .skipMoreInfo, 
    .indicatorForm .skipMoreInfo:active {
        margin-left: -6.7vw !important;
        text-align: right !important;
    }

    .indicatorForm .moreInfoDisabled {
        width: 100% !important;
    }






    
    /* FOOTER */
    .footer .mobile .copyRight {
        max-width: 100% !important;
    }

}









/* iPhone 6/7/8 *******/
@media screen and (max-width: 375px) and (orientation: portrait) {


    /* RIGHT SIDE BAR */
    #profileTooltip .tooltip-arrow {
        left: 4.4em !important;
    }

    






    /* Dashboard/Workbench */
    #dashboard .innerPage.extraSpace {
        margin-bottom: 54em !important;
    }






    /* iCard Modal */
    .iCardModal .downloadBtn {
        top: 76vh !important;
    }
 






    /* SUBMIT AN INDICATOR PAGE */
    .indicatorForm .summaryFormGroup .textArea,
    .indicatorForm .summaryFormGroup .taUnderLineOne {
        margin-top: 2.8em !important
    }

    .indicatorForm .summaryFormGroup .taUnderLineTwo {
        margin-top: -0.3em !important;
    } 


}









/* iPHONE 5/iPhone X/ SMALL PHONES *******/
@media screen and (max-width: 320px) and (orientation: portrait) {


    /* RIGHT SIDE BAR */
    #profileTooltip .tooltip-arrow {
        left: 3.5em !important;
    }




    /* INDICATOR FORM */
    .indicatorForm .skipMoreInfo, 
    .indicatorForm .skipMoreInfo:active {
        margin-left: -8.7vw !important;
    }
}